import { withOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import { Routes } from '../AppConstants';
import i18n from '../i18n';
import { LoadingIndicator } from '../Shared/LoadingIndicator';
import { ILoginProps, ILoginState } from './duck/Models'

class Login extends React.Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);
    this.state = {
        userInfo: null
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  public async componentDidMount(){
    localStorage.removeItem('tableColumnSelection');
    if(this.props.authState && this.props.authState.isAuthenticated){
      const userInfo = await this.props.oktaAuth.getUser() || null;
      this.setState({userInfo})
    }
  }

  public async login() {
    await this.props.oktaAuth.signInWithRedirect({
      originalUri: Routes.home
    });
  }

  public async logout() {
    await this.props.oktaAuth.signOut();
  }

  public render() {
    if (!this.props.authState) {
      return <h1>{i18n.t("auth.loadingMessage")}</h1>
    }
    else {
      this.login();
    }
    return <LoadingIndicator busy={!this.props.authState.isAuthenticated} />
  }
}

export default withOktaAuth(Login);
