import { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAnalyticsState } from "../../duck/Models";
import { IStoreState } from "src/ts/Model";
import { AlertBar, HeaderField, Table, TableBody, TableField, TableHeader, TableRow } from "@panviva/panviva-react-ui";
import './AnalyticsTable.scss';
import * as moment from "moment";
import { LoadingIndicator } from "../../../../ts/Shared/LoadingIndicator";
import { IAnalyticsRequestParams } from "../../container/AnalyticsPage";
import ColumnSelector from "../ColumnSelection/ColumnSelector";
import { TextFilter } from "../Filters/TextFilter";
import { NumberFilter } from "../Filters/NumberFilter";
import { SelectFilter } from "../Filters/SelectFilter";
import { DateFilter } from "../Filters/DateFilter";
import { Tooltip } from "react-tooltip";
import { clearCSVExportStatus } from "../../duck/Actions";

interface IAnalyticsTableProps {
  onRequestChange: (requestParams: IAnalyticsRequestParams) => void;
  onClickExport: (requestParams: IAnalyticsRequestParams, columnList: any) => void;
  onFacetChange: (propertyName:string) => void;
}

export type AlertColor =
    | undefined
    | 'neutral'
    | 'pos-progress'
    | 'neg-progress'
    | 'active'
    | 'error'
    | 'success'
    | 'danger'
    | 'information'
    | 'warning'
    | 'completed'
    | 'in-progress'
    | 'default';

const GridTitles = [{ name: 'Response Title', property: 'title', checked: true, disabled: true, type: 'text' },
  { name: 'Response ID', property: 'referenceId', checked: true, disabled: true, type: 'number' },
  { name: 'Panviva ID', property: 'panvivaDocumentId', checked: true, disabled: true, type: 'number' },
  { name: 'Primary Query', property: 'primaryQuery', checked: true, disabled: false, type: 'text' },
  { name: 'Query Variations', property: 'queryVariationCount', checked: true, disabled: false, type: 'number' },
  { name: 'Channels', property: 'channels', checked: true, disabled: false, type: 'dropdown', multiSelection: true, hideSort: true },
  { name: 'Category', property: 'category/name', checked: true, disabled: false, type: 'dropdown', multiSelection: true}, 
  { name: 'Keywords', property: 'metaData/keyword/values', checked: true, disabled: false, type: 'dropdown', multiSelection:true, searchable: true, hideSort: true},
  { name: 'Status', property: 'state', checked: true, disabled: false, type: 'dropdown', menuOptions: ['Draft','Published'] },
  { name: 'Created Date & Time', property: 'dateCreated', checked: true, disabled: false, type: 'date' },
  { name: 'Updated Date & Time', property: 'dateModified', checked: true, disabled: false, type: 'date' },
  { name: 'Created By (Username)', property: 'createUserName', checked: true, disabled: false, type: 'text' },
  { name: 'Updated By (Username)', property: 'lastUpdatedUserName', checked: true, disabled: false, type: 'text' },
  { name: 'Response Hash', property: 'responseId', checked: true, disabled: false, type: 'text' }];

const AnalyticsTable: FunctionComponent<IAnalyticsTableProps> = (props) => {

  const dispatch = useDispatch();
  const { onRequestChange, onClickExport, onFacetChange } = props;
  const analyticsDetails = useSelector((state: IStoreState) => state.analytics);
  const [notification, setNotification] = useState<{
    text: string;
    severity: AlertColor;
}>();
  const [analyticsData, setAnalyticsData] = useState<IAnalyticsState>();
  const [requestParams, setRequestParams] = useState<IAnalyticsRequestParams>({ pageNumber: 1, pageSize: 30, skip: 0, filter: { filters: [] }, orderBy: 'dateModified desc' });
  const columnSelectionState = JSON.parse(localStorage.getItem('tableColumnSelection') || JSON.stringify(GridTitles));

  useEffect(()=>{
    return(()=>{
      dispatch(clearCSVExportStatus());
    })
  },[])

  useEffect(() => {
    setAnalyticsData(analyticsDetails);
  }, [analyticsDetails])

  useEffect(() => {
    onRequestChange(requestParams);
  }, [requestParams])

  useEffect(() => {
    initCustomAttribute();
  }, [analyticsData])

  useEffect(()=>{
    analyticsData?.csvExport?.fetchedCSV && setNotification({text: 'File downloaded successfully.', severity: 'success'});
    analyticsData?.csvExport?.fetchFailedCSV && setNotification({text: 'Download failed. Please try again.', severity: 'error'});

    const timeId = setTimeout(() => {
      // After 3 seconds clear notification
      setNotification(undefined);
    }, 3000);
    return () => {
      clearTimeout(timeId);
    }
  },[analyticsData?.csvExport])

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    setRequestParams({
      ...requestParams,
      pageSize: parseInt(event.target.value, 10),
      pageNumber: 1,
      skip: 0
    });
  };

  const handleFirstPageChange = () => {
    setRequestParams({ ...requestParams, pageNumber: 1, skip: 0 })
  };

  const handlePrevPageChange = () => {
    setRequestParams({ ...requestParams, pageNumber: requestParams.pageNumber - 1, skip: requestParams.skip - requestParams.pageSize })
  };

  const handleNextPageChange = () => {
    setRequestParams({ ...requestParams, pageNumber: requestParams.pageNumber + 1, skip: requestParams.skip + requestParams.pageSize })
  };

  const handleLastPageChange = () => {
    setRequestParams({
      ...requestParams,
      pageNumber: (analyticsData?.total && analyticsData?.total > 0)
        ? Math.ceil(analyticsData?.total / requestParams.pageSize)
        : 1,
      skip: (analyticsData?.total && analyticsData?.total > 0)
      ? (Math.ceil(analyticsData.total / requestParams.pageSize) - 1) * requestParams.pageSize
      : 0
    })
  };

  const setColumnHeaderSort = (columnIndex:number) => {

    const table = document.getElementById('analytics-custom-table');
    const columnHeaders = [].slice.call(table?.querySelectorAll('th'));

    for (var i = 0; i < columnHeaders.length; i++) {
      var ch = columnHeaders[i];
      var spanNode = ch.querySelector('span');
      if (i == columnIndex) {
        var value = ch.getAttribute('aria-sort');
        if (value === 'descending') {
          ch.setAttribute('aria-sort', 'ascending');
          setRequestParams({...requestParams, pageNumber: 1, skip: 0, orderBy: `${GridTitles[columnIndex].property} asc`});
        } else {
          ch.setAttribute('aria-sort', 'descending');
          setRequestParams({...requestParams, pageNumber: 1, skip: 0, orderBy: `${GridTitles[columnIndex].property} desc`});
        }
      } else {
        if (ch.hasAttribute('aria-sort') && spanNode) {
          ch.removeAttribute('aria-sort');
        }
      }
    }
  }
  
  const handleClickHeader = (event:any) =>{
    var tgt = event.currentTarget.closest('th');
    setColumnHeaderSort(tgt.getAttribute('data-column-index'));
  }

  const initCustomAttribute = () => {
    const table = document.getElementById('analytics-custom-table');
    if (table) {
      const cells = [].slice.call(table.querySelectorAll('th, td'));
      cells.filter(function (cell: any) {
        // skip custom properties columns
        return cell.getAttribute('data-column-type') !== "custom";
      }).forEach(function (cell: any, index: number) {
        const columnIndex = index % GridTitles.length;
        cell.setAttribute('data-column-index', columnIndex);
        // check the existing state in localstorage and set show/hide when initialise the page
        const checked = JSON.parse(localStorage.getItem('tableColumnSelection') || JSON.stringify(columnSelectionState))[columnIndex]?.checked
        cell.style.display = checked ? '' : 'none';
      });
    }
  }

  const handleColumnSelection = (event: any, columnIndex: number, updatedState:any[], selectAll?: boolean) => {
    const table = document.getElementById('analytics-custom-table');
    if (table) {
      const cells = [].slice.call(table.querySelectorAll('th, td'));
      if (selectAll) {
        cells.filter(function (cell: any) {
          // skip first 3 mandatory disabled columns when show/hide all
          return cell.getAttribute('data-column-index') > 2;
        }).forEach(function (cell: any) {
          cell.style.display = event.target.checked ? '' : 'none';
        });
      } else {
        cells.filter(function (cell: any) {
          return cell.getAttribute('data-column-index') === columnIndex + '';
        }).forEach(function (cell: any) {
          cell.style.display = event.target.checked ? '' : 'none';
        });
      }
      localStorage.setItem('tableColumnSelection', JSON.stringify(updatedState));
    }
  }

  const handleFilterApply = (
    field: string,
    value: any,
    operator: string = 'eq',
    hasSecondFilter: boolean = false
  ) => {
    if (hasSecondFilter) {
      handleSecondFilter(field, value);
    } else {
      const newObj = { field, value, operator };
      const updatedFilters = JSON.parse(
        JSON.stringify(requestParams.filter?.filters)
      );
      const index = requestParams.filter.filters.findIndex(
        (obj: any) => obj.field === field
      );
      if (index > -1) {
        value
          ? (updatedFilters[index].value = value)
          : updatedFilters.splice(index, 1);
      } else {
        value && updatedFilters.push(newObj);
      }
      setRequestParams({
        ...requestParams,
        pageNumber: 1,
        skip: 0,
        filter: { filters: updatedFilters }
      });
    }
  };

  const handleSecondFilter = (field: string, value: any) => {
    const updatedFilters = JSON.parse(
      JSON.stringify(requestParams.filter?.filters)
    );
    Object.keys(value).forEach((element: any) => {
      const index = requestParams.filter.filters.findIndex(
        (obj: any) =>
          obj.field === field && obj.operator === value[element].operator
      );
      const newObj = {
        field,
        value: value[element].value,
        operator: value[element].operator
      };
      if (index > -1) {
        (newObj.value || newObj.value === 0)
          ? (updatedFilters[index].value = newObj.value)
          : updatedFilters.splice(index, 1);
      } else {
        (newObj.value || newObj.value === 0) && updatedFilters.push(newObj);
      }
    });
    setRequestParams({
      ...requestParams,
      pageNumber: 1,
      skip: 0,
      filter: { filters: updatedFilters }
    });
  };

  const handleFilterClear = (field: string) => {
    const updatedFilters = JSON.parse(
      JSON.stringify(requestParams.filter?.filters)
    );
    const filteredList = updatedFilters.filter(
      (obj: any) => obj.field !== field
    );
    setRequestParams({
      ...requestParams,
      pageNumber: 1,
      skip: 0,
      filter: { filters: filteredList }
    });
  };

const renderFilter = (item:any) => {
  switch(item.type){
    case "text":
      return <TextFilter
                field={item}
                value={requestParams.filter.filters?.find((obj:any) => obj.field === item.property)?.value}
                onFilterApply={handleFilterApply}
                onClear={handleFilterClear} />
    case 'number':
      return <NumberFilter 
                field={item}
                firstFilterValue={requestParams.filter.filters?.find((obj:any) => obj.field === item.property && obj.operator === 'ge')?.value}
                secondFilterValue={requestParams.filter.filters?.find((obj:any) => obj.field === item.property && obj.operator === 'le')?.value}
                onFilterApply={handleFilterApply}
                onClear={handleFilterClear} />
    case 'dropdown':
      return <SelectFilter 
                field={item}
                value={requestParams.filter.filters?.find((obj:any) => obj.field === item.property)?.value}
                asyncloader={onFacetChange}
                onFilterApply={handleFilterApply}
                onClear={handleFilterClear} />
    case 'date':
      return <DateFilter 
                field={item}
                firstFilterValue={requestParams.filter.filters?.find((obj:any) => obj.field === item.property && obj.operator === 'ge')?.value}
                secondFilterValue={requestParams.filter.filters?.find((obj:any) => obj.field === item.property && obj.operator === 'le')?.value}
                onFilterApply={handleFilterApply}
                onClear={handleFilterClear} />
    default:
      return ''
  }
}

const sortByKey = (unordered:any) =>{
  const ordered = Object.keys(unordered).sort().reduce(
    (obj:any, key) => { 
      obj[key] = unordered[key]; 
      return obj;
    }, 
    {}
  );
  return ordered;
}

const handleClickExport = () =>{
  const titleandproperty = GridTitles.map(field => ({ [field.name] : field.property }));
  let queryObj:any = {};
  titleandproperty.forEach((obj)=>{
    Object.keys(obj).forEach((key) => {
      queryObj[key] = obj[key]
  });
  })
  //adding custom properties for export
  const titleandpropertyCustom = analyticsData?.data[0]?.metaData && Object.entries<any>(sortByKey(analyticsData.data[0].metaData)).filter(([property])=>{
    // skip keyword property
    return property !== "keyword"
  })
  .slice(0, 10).map(([key]) => {
    return key !== "keyword" && ({ [key] : `metaData/${key}/values` })
  })
  titleandpropertyCustom?.forEach((obj:any)=>{
    Object.keys(obj).forEach((key) => {
      queryObj[key] = obj[key]
  });
  })
  onClickExport(requestParams, encodeURIComponent(JSON.stringify(queryObj)));
}

const closeNotification = () => {
  setNotification(undefined);
  dispatch(clearCSVExportStatus());
};

  return (
    <div className="analytics-table">

      <ColumnSelector columnList={columnSelectionState || GridTitles} onColumnSelection={handleColumnSelection} onExport={handleClickExport}/>

      <Table
        id='analytics-custom-table'
        className="analytics-table-container"
        pageSizeOptions={[10,20,30,50,100]}
        totalCount={analyticsData?.total}
        totalPages={analyticsData?.total ? Math.ceil(analyticsData.total / requestParams.pageSize) : 0}
        currentPage={requestParams.pageNumber}
        rowsPerPage={requestParams?.pageSize}
        setRowsPerPage={(e: React.ChangeEvent<HTMLSelectElement>) =>
          handleChangeRowsPerPage(e)
        }
        handleFirstPage={handleFirstPageChange}
        handlePrevPage={handlePrevPageChange}
        handleNextPage={handleNextPageChange}
        handleLastPage={handleLastPageChange}
      >

        <TableHeader className="analytics-table-header">
          <TableRow type="header">
            {GridTitles.map((item: any) => {
              return <HeaderField key={item.name} >
                <span className="analytics-table-heading">
                  { item.name}{' '}{!item.hideSort && <i aria-hidden="true" className="sort-icon" onClick={handleClickHeader}/>}
                  {renderFilter(item)}
                </span>
              </HeaderField>
            })}
            {/* custom properties */}
            {analyticsData?.data[0]?.metaData && Object.entries<any>(sortByKey(analyticsData.data[0].metaData)).slice(0, 10).map(([key, obj]) => {
              return key !== 'keyword' && <HeaderField key={key} data-column-type="custom">
                <span className="analytics-table-heading">
                  {key}
                  {/* {renderFilter({ name: key, property: `metaData/${key}/values`, checked: true, disabled: false, type: 'text' })} */}
                </span>
              </HeaderField>
            })}
          </TableRow>
        </TableHeader>

        <LoadingIndicator busy={(analyticsData?.fetching || analyticsData?.csvExport?.fetchingCSV) || false} />

        {analyticsData?.error ?
          <div className="analytics-table-error">
            <p>{analyticsData?.error}</p>
          </div>
          :
          <TableBody>
            {analyticsData?.data?.map((cell: Api.IAnalyticsData, index: number) => {
              return <TableRow key={`${cell.title}_${index}`}>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-cell-name"
                    data-tooltip-id={`${cell.title}-default-tooltip`}
                    data-tooltip-content={cell.title?.toString()}>
                    {cell.title}
                  </div>
                  <Tooltip
                    id={`${cell.title}-default-tooltip`}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-cell-name">
                    {cell.referenceId}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-cell-name">
                    {cell.panvivaDocumentId}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-cell-name"
                    data-tooltip-id={`${cell.primaryQuery}-default-tooltip`}
                    data-tooltip-content={cell.primaryQuery?.toString()}>
                    {cell.primaryQuery}
                  </div>
                  <Tooltip
                    id={`${cell.primaryQuery}-default-tooltip`}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-cell-name">
                    {cell.queryVariationCount?.toString()}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-table-tags-data"
                    data-tooltip-id="channels-default-tooltip"
                    data-tooltip-content={cell.channels?.join(", ")}>
                    {cell.channels?.slice(0, 4).map((tag: string) => {
                      return <span
                        key={tag}
                        className="analytics-cell-tags">
                        {tag}
                      </span>
                    })}
                  </div>
                  <Tooltip
                    id={"channels-default-tooltip"}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-table-tags-data"
                    data-tooltip-id="category-default-tooltip"
                    data-tooltip-content={cell.category?.name}>
                    <span
                      key={cell.category?.name}
                      className={cell.category?.name ? "analytics-cell-tags" : "analytics-cell-name"}>
                      {cell.category?.name}
                    </span>
                  </div>
                  <Tooltip
                    id={"category-default-tooltip"}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-table-tags-data"
                    data-tooltip-id="keywords-default-tooltip"
                    data-tooltip-content={cell.keywords?.join(", ")}>
                    {cell.keywords?.slice(0, 4).map((tag: string) => {
                      return <span
                        key={tag}
                        className="analytics-cell-tags">
                        {tag}
                      </span>
                    })}
                  </div>
                  <Tooltip
                    id={"keywords-default-tooltip"}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />
                </TableField>
                <TableField className="analytics-table-data">
                  <div className="analytics-cell-name">
                    {cell.state}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div className="analytics-cell-name">
                    {cell.dateCreated ? moment(cell.dateCreated).utc().format("MM/DD/YYYY hh:mm A") : ''}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div className="analytics-cell-name">
                    {cell.dateModified ? moment(cell.dateModified).utc().format("MM/DD/YYYY hh:mm A") : ''}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div className="analytics-cell-name">
                    {cell.createUserName}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div className="analytics-cell-name">
                    {cell.lastUpdatedUserName}
                  </div>
                </TableField>
                <TableField className="analytics-table-data">
                  <div
                    className="analytics-cell-name"
                    data-tooltip-id={`${cell.responseId}-default-tooltip`}
                    data-tooltip-content={cell.responseId.toString()}>
                    {cell.responseId}
                  </div>
                  <Tooltip
                    id={`${cell.responseId}-default-tooltip`}
                    place={"right"}
                    variant={"info"}
                    className={"cc-tooltip analytics-tooltip"} />
                </TableField>
                {/* custom properties */}
                {cell.metaData && Object.entries<any>(sortByKey(cell.metaData)).slice(0, 10).map(([key, obj]) => {
                  const formatValue = obj.values?.map((value:string)=>{
                    if(value.match(/[0-9]+T[0-9]+/)){
                      return moment(value).utc().format("MM/DD/YYYY hh:mm A")
                    }
                    return value;
                  }).join(', ');
                  return key !== 'keyword' && <TableField data-column-type="custom" key={key} className="analytics-table-data">
                          <div className="analytics-cell-name"
                              data-tooltip-id={`${key}-default-tooltip`}
                              data-tooltip-content={formatValue}>
                            {formatValue}
                          </div>
                          <Tooltip
                            id={`${key}-default-tooltip`}
                            place={"right"}
                            variant={"info"}
                            className={"cc-tooltip analytics-tooltip"} />
                        </TableField>
                })}
              </TableRow>
            })}
          </TableBody>
        }
      </Table>

      { notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}

    </div>);
}

export default AnalyticsTable;
